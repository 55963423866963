
import getColor from 'utils/getThemeColor'

const colors = {};

const colorVals: ObjectOf<colorVal> = {
  green: [
    {stop: 0, h: 158, s: 61},
    {stop: 100, h: 158, s: 61}
  ],
  gray: [
    {stop: 0, h: 158, s: 0},
    {stop: 100, h: 158, s: 0}
  ],
  offGray: [
    {stop: 0, h: 158, s: 10},
    {stop: 100, h: 158, s: 10}
  ]
};

const breakpoints = [0, 480, 768, 1000, 1300, 1600, 1900];

const spacing = {
  outerGutterWidth: ['1rem', '1rem', '1.5rem', '1.5rem', '2rem', '2rem'],
  pageWrapperWidth: ['0', '0', '0', '0', '0', '100rem']
};

const typography = {
  fontFamilyDefault: '"Inter", Arial, Helvetica, sans-serif',
  fontFamilySecondary: '"Roboto Condensed", Arial, Helvetica, sans-serif'
};

const theme = {
  typography, 
  colors, 
  spacing, 
  breakpoints
};

const color = {
  get: getColor,
  green: (lightness) => getColor(colorVals.green, lightness),
  gray: (lightness) => getColor(colorVals.gray, lightness),
  offGray: (lightness) => getColor(colorVals.offGray, lightness)
};


const bp = (index: number, upto = false) => {
  return breakpoints[upto ? index : index-1] + 'px'
};

export {bp, color};

export default theme; 