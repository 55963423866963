import { createGlobalStyle } from 'styled-components';
import resetCss from 'styles/reset';

import theme, {bp, color} from 'settings/theme';

const GlobalStyle = createGlobalStyle`

  ${resetCss}

  html, body {
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0 !important;
    color: ${color.gray(95)};
    letter-spacing: -0.04em;
    font-weight: 400;
    background-color: ${color.offGray(8)};
    overflow: initial !important;
  }

  html, body, #__next, #__next main{
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 0.75rem;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: ${color.offGray(20)};
  }
  ::-webkit-scrollbar-track {
    background: ${color.offGray(8)};
  }

  #__next{
    z-index: 0;
  }

  #headlessui-portal-root{
    z-index: 1;
  }

  body{overflow-y: scroll !important;}

  button{
    font-family: inherit;
    cursor: pointer;
    color: white;
  }

  a{
    text-decoration: none;
  }
  
  h1{
    font-size: 1.625rem;
    margin-bottom: 1rem;
  }


`;

export default GlobalStyle;
