import { useRouter } from 'next/router';
import Head from 'next/head';
import { Toaster } from 'react-hot-toast';
import WalletConnectionProvider from 'providers/wallet/WalletConnectionProvider';
import { trpc } from 'utils/trpc';
import { SessionProvider } from 'next-auth/react';
import ManageAuthGate from 'components/auth/manageAuthGate';
import { LayoutProvider } from 'providers/layout/layoutProvider';

import GlobalStyle from 'styles/globalstyles';

import { Figtree } from 'next/font/google';

const figtree = Figtree({ subsets: ['latin'] });

type AppPropsExtended<P = {}> = (import('next/app').AppProps<P>) & {
  Component: NextPageExtended<P>
}

const MyApp = ({ Component, pageProps }: AppPropsExtended<{session: any}>) => {

  const getLayout = Component.getLayout ?? ((page) => page);
  const route = useRouter().pathname;

  return (
    <SessionProvider session={pageProps.session}>
      <WalletConnectionProvider>
        <LayoutProvider>

          <Head>
            <meta name="viewport" content='initial-scale=1.0, width=device-width, height=device-height' />
          </Head>

          <style jsx global>{`
            html {
              font-family: ${figtree.style.fontFamily};
            }
          `}</style>

          <GlobalStyle />

            {Component.auth ? (
              <ManageAuthGate roles={Component.auth?.roles}>
                {getLayout(<Component key={route} {...pageProps}></Component>)}
              </ManageAuthGate>
            ) : (
              <>
                {getLayout(<Component key={route} {...pageProps}></Component>)}
              </>
            )}

          <Toaster
            position="bottom-center"
            gutter={8}
            containerClassName="toast-container"
            toastOptions={{
              className: 'toast',
              duration: 2000
            }}
          />

        </LayoutProvider>
      </WalletConnectionProvider>
    </SessionProvider>
  );

}

export default trpc.withTRPC(MyApp);