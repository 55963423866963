import { FC, ReactNode, useCallback, useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import { SlopeWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

const WalletConnectionProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const network =
    process.env.NEXT_PUBLIC_SOLANA_CLUSTER === 'mainnet' ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(
    () => {
      // return "https://solana-devnet.g.alchemy.com/v2/el6HDIEb0cGOH5vK0Mr9kYsNi2LNtz6_"
      // return "http://marcopolo1.nodemonkey.io:8899/"
      // return "http://api.mainnet-beta.solana.com"
      // return "https://empty-purple-pine.solana-mainnet.discover.quiknode.pro/088a58508e74341cc992266f452dbea3cc7c2892/"
      return "https://rpc.helius.xyz/?api-key=d406f84c-50a0-4a68-afac-dc094226e921";
    }
    , [network]);

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [new SlopeWalletAdapter(), new SolflareWalletAdapter({ network })],
    [network]
  );

  const onError = useCallback((error: WalletError) => {
    console.log("Caught Error in Provider", error.name);
  }, []);

  return (
    <ConnectionProvider endpoint={endpoint} >
      <WalletProvider onError={onError} wallets={wallets} autoConnect>
        {children}
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default WalletConnectionProvider;